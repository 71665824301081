/**
 *
 * @param item string to access the items of the messages object, separated by periods
 * @returns a string from the object or the fallback locale
 */
export function useTranslate(item: string) {
  const { t } = useI18n();

  return t(item);
}
